<template>
    <div id="home" class="page-wrapper" v-if="pageIsLoaded">
        <!-- Hero -->
        <section class="main-hero">
            <div class="col col-content">
                <h1 class="big-title" data-inview="fadeInUp" data-delay="100">
                    {{ content.hero.titre }}
                </h1>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    {{ content.hero.texte }}
                </p>
                <div class="addresses" data-inview="fadeInUp" data-delay="400">
                    <address v-for="(item, i) in cliniquesItems" :key="i">
                        <svg
                            width="36"
                            height="46"
                            viewBox="0 0 36 46"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M33.9809 9.70022C31.2582 4.32373 25.9915 0.705337 20.005 0.0995258C18.6712 -0.0331753 17.3287 -0.0331753 15.9971 0.0995258C10.0084 0.707715 4.74206 4.32373 2.01902 9.69796C0.331939 13.0729 -0.319454 16.8726 0.146465 20.6167C0.612383 24.3609 2.17357 27.8842 4.63573 30.7417L16.6353 45.3542C16.971 45.7634 17.4722 46 18 46C18.5278 46 19.029 45.7634 19.3647 45.3542L31.3643 30.7417C33.8264 27.8843 35.3876 24.3609 35.8535 20.6167C36.3195 16.8726 35.6681 13.0752 33.981 9.70022H33.9809ZM17.9999 24.7679C15.1425 24.7679 12.5655 23.0429 11.4724 20.4C10.3771 17.7549 10.9822 14.7093 13.0027 12.6858C15.0231 10.6622 18.0638 10.0562 20.7048 11.1532C23.3437 12.2479 25.066 14.8289 25.066 17.6908C25.0616 21.5965 21.8994 24.7635 17.9998 24.7681L17.9999 24.7679Z"
                                fill="#565B6E"
                            />
                        </svg>
                        <p class="small-text">
                            <a
                                :href="'https://www.google.com/maps/place/' + item.adresse.court"
                                target="_blank"
                                rel="noopener noreferrer"
                                >{{ item.adresse.court }}</a
                            >
                        </p>
                    </address>
                </div>
                <div data-inview="fadeInUp" data-delay="500">
                    <a
                        href="#"
                        @click.prevent="togglePanel('rendez-vous')"
                        class="site-btn alternate"
                        title="Prendre rendez-vous"
                        >{{ content.hero.bouton.customText }}</a
                    >
                </div>
            </div>
            <div class="col col-img" data-inview="revealRight">
                <img
                    class="lazyload"
                    v-if="content.hero.image.kind === 'image'"
                    :data-src="content.hero.image.url"
                    :alt="content.hero.image.title"
                />
                <video v-else autoplay muted loop playsinline>
                    <source :src="content.hero.image.url" type="video/mp4">
                </video>
            </div>
        </section>

        <!-- Home Slider -->
        <section class="home-slider">
            <div class="section-heading-n-btn">
                <h2 class="regular-title" data-inview="fadeInUp" data-delay="100">
                    <span v-html="content.slider.titre"></span>
                </h2>
                <div data-inview="fadeInUp" data-delay="300">
                    <router-link :to="content.slider.boutonUrl[$route.meta.lang]" class="site-btn" title="Découvrez nos cliniques">{{
                        content.slider.bouton.customText
                    }}</router-link>
                </div>
            </div>

            <div class="clinique-slider extends">
                <div class="main-carousel">
                    <div class="carousel-cell" v-for="(item, i) in content.slider.images" :key="i">
                        <div class="overlay-effect"></div>
                        <img
                            :src="item.url"
                            :alt="item.titre"
                            :title="item.titre"
                        />
                    </div>
                </div>
            </div>
        </section>

        <!-- Home Services Grid -->
        <section class="home-services">
            <div class="col col-content">
                <div class="inner">
                    <svg
                        class="particle"
                        height="46"
                        viewBox="0 0 315 80"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        data-inview="fadeInUp"
                        data-delay="300"
                    >
                        <path
                            d="M0.247544 0C3.46562 1.89689 6.55992 3.9175 9.61297 5.89686C12.666 7.87623 15.9253 9.64941 18.9784 11.5463L28.3851 16.8246C31.5619 18.5566 34.7387 20.1236 37.9155 21.773C50.4423 28.06 63.419 33.408 76.7387 37.7729C102.837 46.2878 130.108 50.6706 157.562 50.7625C185.027 50.7192 212.314 46.3638 238.426 37.8554C251.762 33.4982 264.753 28.1499 277.291 21.8555C280.426 20.2473 283.644 18.639 286.821 16.9483L296.228 11.6288C299.322 9.73188 302.499 7.91746 305.593 6.02057C308.688 4.12368 311.782 1.89689 315 0.0824755C312.978 3.21647 310.874 6.22676 308.564 9.23705C306.253 12.2473 303.819 15.0514 301.385 17.8968C298.951 20.7421 296.228 23.34 293.587 26.1441C292.308 27.5462 290.864 28.7008 289.462 30.0204L285.336 33.8142C273.724 43.7532 260.935 52.2289 247.255 59.0511C245.564 59.9583 243.79 60.7006 242.057 61.5253L236.776 63.917C235.043 64.7418 233.228 65.3191 231.413 66.0613L226.008 68.1232C222.377 69.4428 218.705 70.5562 214.992 71.752C211.279 72.9479 207.525 73.7314 203.77 74.7211C188.646 78.2304 173.171 80.0014 157.644 79.9994C126.547 80.0766 95.8565 72.9337 67.9921 59.1336C54.3074 52.3205 41.5171 43.844 29.9116 33.8967L25.7859 30.1029C24.4244 28.8245 22.9391 27.6286 21.6601 26.2678C19.0196 23.505 16.3379 20.9071 13.8625 18.0205C11.387 15.1339 8.99411 12.2886 6.68369 9.36075C4.37328 6.43294 2.06287 3.29894 0 0.206181L0.247544 0Z"
                            fill="#BEE0D9"
                        />
                    </svg>

                    <h2 class="small-title" data-inview="fadeInUp" data-delay="100">
                        {{ content.traitements.titre }}
                    </h2>
                    <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                        {{ content.traitements.texte }}
                    </p>
                    <router-link
                        :to="content.traitements.boutonUrl[$route.meta.lang]"
                        class="site-btn"
                        title="Découvrez nos traitements"
                        data-inview="fadeInUp"
                        data-delay="400"
                        >{{ content.traitements.bouton.customText }}</router-link
                    >
                </div>
            </div>
            <div class="col col-thumbs" data-inview="revealRightLight" data-delay="200">
                <a href="#" title="" class="thumb is-ghost"> </a>

                <router-link
                    :to="item.jsonUrl"
                    :title="item.title"
                    class="thumb"
                    :class="{ 'is-ghost': item.title === 'Ghost' }"
                    v-for="(item, i) in traitementsListWithGhosts"
                    :key="i"
                >
                    <h3 class="small-title" v-if="item.title !== 'Ghost'">
                        {{ item.title }}
                    </h3>

                    <img
                        :src="item.icon"
                        class="icon"
                        height="45"

                        v-if="item.title !== 'Ghost'"
                    />

                    <svg
                        class="plus"
                        width="35"
                        height="35"
                        viewBox="0 0 60 60"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        v-if="item.title !== 'Ghost'"
                    >
                        <circle cx="30" cy="30" r="30" fill="white" />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M30 40C30.9266 40 31.6931 39.2335 31.6931 38.3069V31.6931H38.3069C39.2335 31.6931 40 30.9266 40 30C40 29.0734 39.2335 28.3069 38.3069 28.3069H31.6931V21.6931C31.6931 20.7665 30.9266 20 30 20C29.0734 20 28.3069 20.7665 28.3069 21.6931V28.3069H21.6931C20.7665 28.3069 20 29.0734 20 30C20 30.9266 20.7665 31.6931 21.6931 31.6931H28.3069V38.3069C28.3069 39.2335 29.0734 40 30 40V40Z"
                            fill="#89AEAC"
                        />
                    </svg>
                </router-link>
            </div>
        </section>

        <section class="home-arguments" :class="{ 'spacing': $route.meta.lang === 'en' }">
            <div class="arguments-container">
                <h2 class="argument-heading"
                    data-inview="fadeInUp"
                    data-delay="400"
                >{{ content.arguments.titre }}</h2>
                <div class="arguments-list">
                    <div
                        v-for="(item, index) in content.arguments.arguments"
                        :key="index"
                        class="argument-item"
                        data-inview="fadeInUp"
                        data-delay="400"
                    >
                        <img :src="item.icon" :alt="item.text">
                        <p class="regular-text">{{ item.text }}</p>
                    </div>
                </div>
                <router-link
                    :to="content.arguments.boutonUrl[$route.meta.lang]"
                    class="site-btn"
                    :title="content.arguments.bouton.customText"
                    data-inview="fadeInUp"
                    data-delay="400"
                    >{{ content.arguments.bouton.customText }}</router-link
                >
            </div>
        </section>

        <!-- Home Ressources -->
        <section class="home-ressources">
            <div class="section-heading-n-btn">
                <h2 class="regular-title" data-inview="fadeInUp" data-delay="100">
                    <span v-html="content.ressources.titre"></span>
                </h2>
                <router-link
                    :to="content.ressources.boutonUrl[$route.meta.lang]"
                    class="site-btn"
                    :title="content.ressources.bouton.customText"
                    data-inview="fadeInUp"
                    data-delay="300"
                    >{{ content.ressources.bouton.customText }}</router-link
                >
            </div>

            <div class="listing" data-inview="fadeInUp" data-delay="200">
                <router-link
                    :to="item.jsonUrl"
                    class="thumb"
                    :title="item.title"
                    v-for="(item, i) in ressourcesList"
                    :key="i"
                >
                    <article>
                        <div class="img-wrap" data-inview="revealRight">
                            <img
                                class="lazyload"
                                :data-src="item.image.url"
                                :alt="item.image.title"
                            />
                        </div>
                        <div class="content">
                            <h3 class="title regular-text bold">
                                {{ item.title }}
                            </h3>
                            <p class="text regular-text">
                                {{ item.courtText }}
                            </p>
                            <svg
                                class="plus"
                                width="45"
                                height="45"
                                viewBox="0 0 60 60"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="30" cy="30" r="30" fill="#89AEAC" />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M30 40C30.9266 40 31.6931 39.2335 31.6931 38.3069V31.6931H38.3069C39.2335 31.6931 40 30.9266 40 30C40 29.0734 39.2335 28.3069 38.3069 28.3069H31.6931V21.6931C31.6931 20.7665 30.9266 20 30 20C29.0734 20 28.3069 20.7665 28.3069 21.6931V28.3069H21.6931C20.7665 28.3069 20 29.0734 20 30C20 30.9266 20.7665 31.6931 21.6931 31.6931H28.3069V38.3069C28.3069 39.2335 29.0734 40 30 40V40Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </article>
                </router-link>
            </div>
        </section>
    </div>
    <div id="home" class="page-wrapper" v-else>
        <PageLoader />
    </div>
</template>

<script>
import Flickity from 'flickity'
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { mapState, mapActions, mapGetters } from 'vuex'
import PageLoader from '@/components/PageLoader.vue'

export default {
    name: 'Home',

    data() {
        return {
            mainCarouselFlk: null,
        }
    },

    components: {
        PageLoader,
    },

    methods: {
        ...mapActions(['togglePanel']),
    },

    computed: {
        traitementsListWithGhosts() {
            if (!this.globalsIsLoaded) {
                return []
            }

            const traitementsItems = [...this.globals.traitementsItems.data]
            traitementsItems.splice(2, 0, {
                id: 0,
                title: 'Ghost',
                url: '#',
                jsonUrl: '',
            })
            return traitementsItems
        },
        ressourcesList() {
            if (!this.globalsIsLoaded) {
                return []
            }

            return this.globals.ressourcesItems.data.slice(0, 2)
        },
        cliniquesItems() {
            if (!this.globalsIsLoaded) {
                return []
            }
            return this.globals.cliniquesItems.data
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    setTimeout(() => {
                        const mainCarousel = document.querySelector('.main-carousel')
                        this.mainCarouselFlk = new Flickity(mainCarousel, {
                            cellAlign: 'center',
                            wrapAround: true,
                            lazyLoad: 1,
                            imagesLoaded: true,
                            prevNextButtons: true,
                            pageDots: false,
                        })

                        initInViewAnimations()

                        setTimeout(() => {
                            this.mainCarouselFlk.resize()

                            setTimeout(() => {
                                this.mainCarouselFlk.resize()
                            }, 1200)
                        }, 1200)
                    }, 155)
                }
            },
            immediate: true,
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page-wrapper {
    min-height: 90vh;
}
</style>
